interface WalletIconProps {
  color: string;
}

export const WalletIcon = ({ color }: WalletIconProps) => {
  return (
    <svg
      style={{
        minHeight: 20,
        maxHeight: 20,
      }}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.375 8.4375H5.625C4.0717 8.4375 2.8125 9.6967 2.8125 11.25V22.5C2.8125 24.0533 4.0717 25.3125 5.625 25.3125H24.375C25.9283 25.3125 27.1875 24.0533 27.1875 22.5V11.25C27.1875 9.6967 25.9283 8.4375 24.375 8.4375Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M24.1031 8.4375V6.67969C24.103 6.24856 24.0077 5.82278 23.8241 5.43272C23.6404 5.04265 23.3729 4.69793 23.0407 4.42314C22.7085 4.14835 22.3198 3.95027 21.9022 3.84303C21.4846 3.73579 21.0485 3.72203 20.625 3.80274L5.19375 6.43653C4.52363 6.56423 3.91907 6.92179 3.48436 7.44753C3.04965 7.97327 2.81204 8.63423 2.8125 9.31641V12.1875"
        stroke={color}
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M21.5625 17.5C21.4389 17.5 21.318 17.4633 21.2153 17.3947C21.1125 17.326 21.0324 17.2284 20.9851 17.1142C20.9378 17 20.9254 16.8743 20.9495 16.7531C20.9736 16.6318 21.0332 16.5205 21.1206 16.4331C21.208 16.3457 21.3193 16.2861 21.4406 16.262C21.5618 16.2379 21.6875 16.2503 21.8017 16.2976C21.9159 16.3449 22.0135 16.425 22.0822 16.5278C22.1508 16.6305 22.1875 16.7514 22.1875 16.875C22.1875 17.0408 22.1217 17.1997 22.0044 17.3169C21.8872 17.4342 21.7283 17.5 21.5625 17.5Z"
        fill="black"
        stroke={color}
        strokeWidth="2.5"
      />
    </svg>
  );
};
