export const GiftIcon = () => {
  return (
    <svg
      style={{
        minHeight: 40,
        maxHeight: 40,
      }}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.96445V10.2457M15 6.96445C15 6.31548 15.1924 5.68108 15.553 5.14148C15.9135 4.60189 16.426 4.18132 17.0256 3.93297C17.6251 3.68462 18.2849 3.61964 18.9214 3.74625C19.5579 3.87285 20.1426 4.18536 20.6014 4.64425C21.0603 5.10315 21.3728 5.68781 21.4995 6.32431C21.6261 6.96081 21.5611 7.62056 21.3127 8.22013C21.0644 8.8197 20.6438 9.33216 20.1042 9.69271C19.5646 10.0533 18.9302 10.2457 18.2813 10.2457H15M15 6.96445C15 6.31548 14.8076 5.68108 14.447 5.14148C14.0865 4.60189 13.574 4.18132 12.9744 3.93297C12.3749 3.68462 11.7151 3.61964 11.0786 3.74625C10.4421 3.87285 9.85745 4.18536 9.39856 4.64425C8.93967 5.10315 8.62716 5.68781 8.50055 6.32431C8.37394 6.96081 8.43892 7.62056 8.68727 8.22013C8.93562 8.8197 9.35619 9.33216 9.89579 9.69271C10.4354 10.0533 11.0698 10.2457 11.7188 10.2457H15"
        stroke="#ECEDEE"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.375 10.2457H5.625C4.58947 10.2457 3.75 11.0852 3.75 12.1207V14.9332C3.75 15.9687 4.58947 16.8082 5.625 16.8082H24.375C25.4105 16.8082 26.25 15.9687 26.25 14.9332V12.1207C26.25 11.0852 25.4105 10.2457 24.375 10.2457Z"
        stroke="#ECEDEE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.375 16.8082V25.2457C24.375 25.9916 24.0787 26.707 23.5512 27.2344C23.0238 27.7619 22.3084 28.0582 21.5625 28.0582H8.4375C7.69158 28.0582 6.97621 27.7619 6.44876 27.2344C5.92132 26.707 5.625 25.9916 5.625 25.2457V16.8082M15 10.2457V28.0582"
        stroke="#ECEDEE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
