import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { useUnit } from "effector-react";
import { $userStore, transferCoins } from "entities/user";

export const TransferCoinsButton = () => {
  const showPopUp = useShowPopup();
  const { coins } = useUnit($userStore);
  const onClick = () => {
    if (coins < 1) {
      showPopUp({
        title: "Error",
        message: "You can claim minimum 1 TRX",
      });
      return;
    }
    transferCoins();
  };
  return (
    <button
      onClick={onClick}
      className="bg-primary text-on-primary rounded-xl font-bold text-2xl w-full py-2  active:opacity-50 transition-all"
    >
      Claim
    </button>
  );
};
